<template>
  <div class="edit-view">
    <page-title>
      <el-button
        @click="$router.go(-1)"
        :disabled="saving"
      >取消</el-button>
      <el-button
        @click="onDelete"
        :disabled="saving"
        type="danger"
        v-if="formData.id"
      > 删除 </el-button>
      <el-button
        @click="onSave"
        :loading="saving"
        type="primary"
      >保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">

    </div>

    <el-dialog
      :visible.sync="showPhoneDialog"
      title="添加空投对象"
      width="600px"
      :close-on-click-modal="false"
      :show-close="!searchingPhone"
    >
      <el-form
        :model="{ phone }"
        ref="phoneForm"
      >
        <el-form-item
          prop="phone"
          :rules="[{ required: true, message: '请输入手机号' }]"
        >
          <el-input
            v-model="phone"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 20 }"
            placeholder="可输入多个手机号，用空格或回车分隔"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          @click="showPhoneDialog = false"
          :disabled="searchingPhone"
          size="mini"
        >取消</el-button>
        <el-button
          @click="savePhone"
          :loading="searchingPhone"
          size="mini"
          type="primary"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
    name: 'AirDropEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('airDrop/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.getBoxList();
        this.$http
            .post('/coupon/all', { size: 10000, query: { del: false }, sort: 'createdAt,desc' }, { body: 'json' })
            .then(res => {
                this.coupons = res.content;
            });
    },
    data() {
        return {
            userInfo: this.$store.state.userInfo || {},
            saving: false,
            boxList: [],
            formData: {
                projectId: this.$store.state.projectId,
                couponLimited: false
            },
            rules: {
                projectId: [{ required: true, message: '请选择项目' }],
                name: [
                    {
                        required: true,
                        message: '请输入空投名称',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入空投类型',
                        trigger: 'blur'
                    }
                ],
                userIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else {
                                callback(new Error('请添加空投对象'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                couponName: [{ required: true, message: '请输入兑换券名称' }],
                couponCollectionIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else {
                                callback(new Error('请添加优惠券可用对象'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                couponExpiration: [{ required: true, message: '请输入兑换券过期时间' }],
                collectionId: [{ required: true, message: '请选择藏品' }],
                surpriseBoxId: [{ required: true, message: '请选择惊喜盒子' }]
            },
            typeOptions: [
                { label: '藏品', value: 'asset' },
                { label: '惊喜盒子', value: 'SURPRISE_BOX' },
                { label: '兑换券', value: 'coupon' }
            ],
            phone: '',
            showPhoneDialog: false,
            searchingPhone: false,
            users: [],
            coupons: []
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/airDrop/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        getBoxList() {
            // this.saving = true;
            const data = {
                page: 0,
                query: {
                    del: false,
                    projectId: this.formData.projectId,
                    createdAt: ''
                },
                size: 10000,
                sort: 'createdAt,desc'
            };
            this.$http
                .post('/activitySurpriseBox/all', data, { body: 'json' })
                .then(res => {
                    this.boxList = res.content;
                    console.log(res, 'box');
                    // this.saving = false;
                    // this.$message.success('成功');
                    // this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    // this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/airDrop/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        addUser() {
            this.phone = '';
            this.showPhoneDialog = true;
            if (this.$refs.phoneForm) {
                this.$nextTick(() => {
                    this.$refs.phoneForm.clearValidate();
                });
            }
        },
        savePhone() {
            this.$refs.phoneForm
                .validate()
                .then(res => {
                    this.searchingPhone = true;
                    this.$http
                        .post('/user/searchByPhoneAdmin', { phone: this.phone })
                        .then(res => {
                            this.showPhoneDialog = false;
                            this.searchingPhone = false;
                            this.users = this.users.concat(res.users.filter(i => !this.users.find(e => e.id === i.id)));
                            if (res.notFound && res.notFound.length) {
                                this.$alert('未找到以下手机对应的用户：<br>' + res.notFound.join('、'), '', {
                                    dangerouslyUseHTMLString: true
                                });
                            }
                        })
                        .catch(e => {
                            this.searchingPhone = false;
                            console.log(e);
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        },
        delUser(index) {
            this.users.splice(index, 1);
        },
        specShow() {
            //特殊显示
            console.log(this.userInfo, 'info');
            let userList = ['admin', 'xingadmin'];
            let showable = userList.indexOf(this.userInfo.username) != -1;
            return showable;
        }
    },
    watch: {
        users(val) {
            if (val) {
                this.formData.userIds = val.map(i => i.id);
                this.formData.phone = val.map(i => i.phone);
            }
        },
        'formData.projectId'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.getBoxList();
            }
        }
    }
};
</script>
<style lang="less" scoped></style>
