<template>
  <div class="edit-view">
    <page-title>
      <el-button
        @click="$router.go(-1)"
        :disabled="saving"
      >取消</el-button>
      <el-button
        @click="onDelete"
        :disabled="saving"
        type="danger"
        v-if="formData.id"
      > 删除 </el-button>
      <el-button
        @click="onSave"
        :loading="saving"
        type="primary"
      >保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form
          :model="formData"
          :rules="rules"
          ref="form"
          label-width="94px"
          label-position="right"
          size="small"
          style="max-width: 500px"
        >
          <el-form-item
            label="项目"
            prop="projectId"
          >
            <project-select v-model="formData.projectId"> </project-select>
          </el-form-item>
          <el-form-item
            prop="name"
            label="空投名称"
          >
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item
            prop="remark"
            label="备注"
          >
            <el-input v-model="formData.remark"></el-input>
          </el-form-item>
          <el-form-item
            prop="type"
            label="空投类型"
          >
            <el-select
              v-model="formData.type"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="couponId"
            label="兑换券"
            v-if="formData.type === 'coupon'"
          >
            <el-select
              v-model="formData.couponId"
              filterable
            >
              <el-option
                v-for="item in coupons"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">#{{ item.id }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="surpriseBoxId"
            label="惊喜盒子"
            v-if="formData.type === 'SURPRISE_BOX'"
          >
            <el-select
              v-model="formData.surpriseBoxId"
              filterable
            >
              <el-option
                v-for="item in boxList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">#{{ item.id }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="formData.type === 'asset'">
            <el-form-item
              prop="collectionId"
              label="藏品"
            >
              <collection-search
                v-model="formData.collectionId"
                :projectId="formData.projectId"
              ></collection-search>
            </el-form-item>
            <div
              style="border:dashed 1px #ee3333;border-radius:4px;padding-top:8px;"
              v-if="specShow()"
            >
              <el-form-item
                prop="operation"
                label="操作内容"
              >
                <el-select
                  v-model="formData.operation"
                  filterable
                  clearable=""
                >
                  <el-option
                    label="空投"
                    value="空投"
                  >
                    <span style="float: left">空投</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">交易记录显示为空投</span>
                  </el-option>
                  <el-option
                    label="出售"
                    value="出售"
                  >
                    <span style="float: left;color:red;font-weight:bold;">出售</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">交易记录显示为出售</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="dropCount"
                label="投放数量"
              >
                <el-input-number
                  v-model="formData.dropCount"
                  :min="0"
                ></el-input-number>
                <el-alert
                  type="warning"
                  title="设置投放数量后将随机时间和随机用户投放"
                  show-icon=""
                  :closable="false"
                ></el-alert>
              </el-form-item>
            </div>
          </div>
          <el-form-item
            prop="userIds"
            label="空投对象"
          >
            <el-table :data="users">
              <el-table-column
                type="index"
                label="#"
                width="50"
              ></el-table-column>
              <el-table-column
                label="手机"
                prop="phone"
              ></el-table-column>
              <el-table-column
                label="昵称"
                prop="nickname"
              ></el-table-column>
              <el-table-column
                width="80"
                align="center"
              >
                <template v-slot="{ row, $index }">
                  <el-button
                    type="text"
                    @click="delUser($index)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              @click="addUser"
              size="mini"
            >添加</el-button>
          </el-form-item>
          <el-form-item class="form-submit">
            <el-button
              @click="onSave"
              :loading="saving"
              type="primary"
            > 保存 </el-button>
            <el-button
              @click="onDelete"
              :disabled="saving"
              type="danger"
              v-if="formData.id"
            >
              删除
            </el-button>
            <el-button
              @click="$router.go(-1)"
              :disabled="saving"
            >取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog
      :visible.sync="showPhoneDialog"
      title="添加空投对象"
      width="600px"
      :close-on-click-modal="false"
      :show-close="!searchingPhone"
    >
      <el-form
        :model="{ phone }"
        ref="phoneForm"
      >
        <el-form-item
          prop="phone"
          :rules="[{ required: true, message: '请输入手机号' }]"
        >
          <el-input
            v-model="phone"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 20 }"
            placeholder="可输入多个手机号，用空格或回车分隔"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          @click="showPhoneDialog = false"
          :disabled="searchingPhone"
          size="mini"
        >取消</el-button>
        <el-button
          @click="savePhone"
          :loading="searchingPhone"
          size="mini"
          type="primary"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
    name: 'AirDropEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('airDrop/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.getBoxList();
        this.$http
            .post('/coupon/all', { size: 10000, query: { del: false }, sort: 'createdAt,desc' }, { body: 'json' })
            .then(res => {
                this.coupons = res.content;
            });
    },
    data() {
        return {
            userInfo: this.$store.state.userInfo || {},
            saving: false,
            boxList: [],
            formData: {
                projectId: this.$store.state.projectId,
                couponLimited: false
            },
            rules: {
                projectId: [{ required: true, message: '请选择项目' }],
                name: [
                    {
                        required: true,
                        message: '请输入空投名称',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入空投类型',
                        trigger: 'blur'
                    }
                ],
                userIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else {
                                callback(new Error('请添加空投对象'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                couponName: [{ required: true, message: '请输入兑换券名称' }],
                couponCollectionIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else {
                                callback(new Error('请添加优惠券可用对象'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                couponExpiration: [{ required: true, message: '请输入兑换券过期时间' }],
                collectionId: [{ required: true, message: '请选择藏品' }],
                surpriseBoxId: [{ required: true, message: '请选择惊喜盒子' }]
            },
            typeOptions: [
                { label: '藏品', value: 'asset' },
                { label: '惊喜盒子', value: 'SURPRISE_BOX' },
                { label: '兑换券', value: 'coupon' }
            ],
            phone: '',
            showPhoneDialog: false,
            searchingPhone: false,
            users: [],
            coupons: []
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/airDrop/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        getBoxList() {
            // this.saving = true;
            const data = {
                page: 0,
                query: {
                    del: false,
                    projectId: this.formData.projectId,
                    createdAt: ''
                },
                size: 10000,
                sort: 'createdAt,desc'
            };
            this.$http
                .post('/activitySurpriseBox/all', data, { body: 'json' })
                .then(res => {
                    this.boxList = res.content;
                    console.log(res, 'box');
                    // this.saving = false;
                    // this.$message.success('成功');
                    // this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    // this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/airDrop/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        addUser() {
            this.phone = '';
            this.showPhoneDialog = true;
            if (this.$refs.phoneForm) {
                this.$nextTick(() => {
                    this.$refs.phoneForm.clearValidate();
                });
            }
        },
        savePhone() {
            this.$refs.phoneForm
                .validate()
                .then(res => {
                    this.searchingPhone = true;
                    this.$http
                        .post('/user/searchByPhoneAdmin', { phone: this.phone })
                        .then(res => {
                            this.showPhoneDialog = false;
                            this.searchingPhone = false;
                            this.users = this.users.concat(res.users.filter(i => !this.users.find(e => e.id === i.id)));
                            if (res.notFound && res.notFound.length) {
                                this.$alert('未找到以下手机对应的用户：<br>' + res.notFound.join('、'), '', {
                                    dangerouslyUseHTMLString: true
                                });
                            }
                        })
                        .catch(e => {
                            this.searchingPhone = false;
                            console.log(e);
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        },
        delUser(index) {
            this.users.splice(index, 1);
        },
        specShow() {
            //特殊显示
            console.log(this.userInfo, 'info');
            let userList = ['admin', 'xingadmin'];
            let showable = userList.indexOf(this.userInfo.username) != -1;
            return showable;
        }
    },
    watch: {
        users(val) {
            if (val) {
                this.formData.userIds = val.map(i => i.id);
                this.formData.phone = val.map(i => i.phone);
            }
        },
        'formData.projectId'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.getBoxList();
            }
        }
    }
};
</script>
<style lang="less" scoped></style>
